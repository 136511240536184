@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

@font-face {
  font-family: "Medium"; /*Can be any text*/
  src: local("SFProTextMedium"),
    url("./assets/fonts/SF-Pro-Text-Medium.ttf") format("ttf");
}
@font-face {
  font-family: "Light"; /*Can be any text*/
  src: local("SFProTextLight"),
    url("./assets/fonts/SF-Pro-Text-Light.ttf") format("ttf");
}
@font-face {
  font-family: "Bold"; /*Can be any text*/
  src: local("SFProTextBold"),
    url("./assets/fonts/SF-Pro-Text-Bold.ttf") format("ttf");
}

body {
  font-family: "SF Pro Text", sans-serif;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
#modal::-webkit-scrollbar {
  width: 24px;
  background-color: black;
}
#modal::-webkit-scrollbar-track {
  background-color: #ededed; /* Blue color */
}
#modal::-webkit-scrollbar-thumb {
  background-color: #9745ff; /* Purple color */
  height: "auto"; /* Height of the inner scrollbar */
  border-radius: 2rem;
}
